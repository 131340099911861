import axios, { Axios } from "axios";
import BaseModel from "@/models/BaseModel.js";

export default class Checkout extends BaseModel {
  fields = {
    name: {
      type: "text",
      label: "Nama",
      rules: "required",
    },
    nik: {
      type: "number",
      label: "NIK",
      rules: "required|minmax:16,16",
    },
    phone_number: {
      type: "text",
      label: "No. Telp",
      rules: "required|min:11",
      format: "phone_number",
    },
    wa_number: {
      type: "text",
      label: "No. Whatsapp",
      rules: "min:11",
      format: "phone_number",
    },
    gender: {
      label: "Jenis Kelamin",
      // firstSelect: 'Pilih jenis Kelamin',
      type: "radio",
      rules: "required",
      options: {
        "pria": "Pria",
        "wanita": "Wanita",
      },
    },
  };

  secondFields = {
    ...this.fields,
    gender: {
      label: "Jenis Kelamin",
      firstSelect: 'Pilih jenis Kelamin',
      type: "enum",
      rules: "required",
      options: {
        "pria": "Pria",
        "wanita": "Wanita",
      },
    },
    self_data: {
      type: "hidden",
    },
    package_id: {
      type: "hidden",
    },
    job: {
      type: "text",
      label: "Pekerjaan",
      rules: "required",
    },
    dob: {
      type: "date",
      label: "Tanggal Lahir",
      rules: "required",
    },
    marriage_status: {
      type: "enum",
      label: "Status",
      rules: "required",
      options: {
        "belum kawin": "Belum Kawin",
        "kawin": "Sudah Kawin",
        "cerai hidup": "Cerai Hidup",
        "cerai mati": "Cerai Mati",
      },
    },
    id_card: {
      type: "attachment",
      label: "Id Card",
      rules: "required",
    },
    self_portrait: {
      type: "attachment",
      label: "Foto Diri",
    },
    province_id: {
      type: "enum",
      label: "Provinsi",
      rules: "required",
      options: {},
    },
    regency_id: {
      type: "enum",
      label: "Kota",
      rules: "required",
      options: {},
    },
    district_id: {
      type: "enum",
      label: "Kecamatan",
      rules: "required",
      options: {},
    },
    village_id: {
      type: "enum",
      label: "Kelurahan",
      rules: "required",
      options: {},
    },
    address: {
      type: "textarea",
      label: "Alamat",
      rules: "required",
    },
  }

  endpoint = process.env.VUE_APP_API_URL + "checkout";
  endpointBank = process.env.VUE_APP_API_URL + "banks";


  init (fields = this.secondFields ,defaultValues = {}) {
    let data = {}
    Object.keys(fields).forEach(key => {
      data[key] = {
        value: typeof defaultValues.value !== 'undefined' ? defaultValues.value : null, 
        ...fields[key]
      }
    })
    return data
  }

  getBankList() {
    return new Promise((resolve, reject) => {
      axios
        .get(this.endpointBank)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  findBank(filter) {
    let query;
    Object.entries(filter).forEach(([key, value]) => {
      query = key + '=' + value + '&'
    })

    query = this.endpointBank + "?" + query;
    return new Promise((resove, reject) => {
      axios
        .get(query)
        .then((resp) => {
          resove(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}