var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('Loading'):_c('b-row',{staticClass:"mb-4"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{staticStyle:{"width":"60%","margin":"0 auto"},attrs:{"header-tag":"header"}},[_c('div',{attrs:{"id":"form-check-out"}},[[_c('div',{ref:"modalJamaah",staticClass:"modal-custom",attrs:{"id":"modal-data-jamaah","scrollable":"","title":"Isi Data Jama'ah"}},[_c('div',{staticClass:"p-3"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"p-3"},[_c('h5',[_vm._v("Data Diri")]),_vm._l((_vm.model),function(m,index,idx){return [(idx < 4)?_c('b-row',[_c('b-col',[_c('form-group-input-vue',{ref:index,refInFor:true,staticClass:"input-gray",attrs:{"placeholder":" ","width-background":"","type":m.type,"label":typeof m.label === 'string' ? m.label : null,"options":_vm.getOptions(index),"need-label":true,"rules":typeof m.rules !== 'undefined' ? m.rules : null,"disabled":typeof m.disabled !== 'undefined'
                                ? m.disabled
                                : null,"id":index,"format":typeof m.format != 'undefined' ? m.format : null},model:{value:(m.value),callback:function ($$v) {_vm.$set(m, "value", $$v)},expression:"m.value"}})],1)],1):_vm._e()]}),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('form-group-input-vue',{ref:"dob",attrs:{"placeholder":" ","width-background":"","type":_vm.model.dob.type,"label":typeof _vm.model.dob.label === 'string'
                              ? _vm.model.dob.label
                              : null,"need-label":true,"rules":typeof _vm.model.dob.rules !== 'undefined'
                              ? _vm.model.dob.rules
                              : null,"id":"dob"},model:{value:(_vm.model.dob.value),callback:function ($$v) {_vm.$set(_vm.model.dob, "value", $$v)},expression:"model.dob.value"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('form-group-input-vue',{ref:"job",attrs:{"placeholder":" ","width-background":"","type":_vm.model.job.type,"label":typeof _vm.model.job.label === 'string'
                              ? _vm.model.job.label
                              : null,"need-label":true,"rules":typeof _vm.model.job.rules !== 'undefined'
                              ? _vm.model.job.rules
                              : null,"id":"job"},model:{value:(_vm.model.job.value),callback:function ($$v) {_vm.$set(_vm.model.job, "value", $$v)},expression:"model.job.value"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('form-group-input-vue',{ref:"gender",attrs:{"placeholder":" ","width-background":"","type":_vm.model.gender.type,"label":typeof _vm.model.gender.label === 'string'
                              ? _vm.model.gender.label
                              : null,"options":_vm.getOptions('gender'),"need-label":true,"rules":typeof _vm.model.gender.rules !== 'undefined'
                              ? _vm.model.gender.rules
                              : null,"disabled":typeof _vm.model.gender.disabled !== 'undefined'
                              ? _vm.model.gender.disabled
                              : null,"id":"gender"},model:{value:(_vm.model.gender.value),callback:function ($$v) {_vm.$set(_vm.model.gender, "value", $$v)},expression:"model.gender.value"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('form-group-input-vue',{ref:"marriage_status",attrs:{"placeholder":" ","width-background":"","type":_vm.model.marriage_status.type,"label":typeof _vm.model.marriage_status.label === 'string'
                              ? _vm.model.marriage_status.label
                              : null,"options":_vm.getOptions('marriage_status'),"need-label":true,"rules":typeof _vm.model.marriage_status.rules !== 'undefined'
                              ? _vm.model.marriage_status.rules
                              : null,"disabled":typeof _vm.model.marriage_status.disabled !==
                            'undefined'
                              ? _vm.model.marriage_status.disabled
                              : null,"id":"marriage_status"},model:{value:(_vm.model.marriage_status.value),callback:function ($$v) {_vm.$set(_vm.model.marriage_status, "value", $$v)},expression:"model.marriage_status.value"}})],1)],1),_c('div',{staticClass:"mt-5"},[_c('h5',[_vm._v("Paket")]),_c('form-group-input-vue',{ref:"packageChosee",attrs:{"placeholder":" ","width-background":"","type":"enum","label":"Paket Pilihan","options":_vm.getOptions(_vm.packageList),"need-label":true,"rules":typeof _vm.model.marriage_status.rules !== 'undefined'
                            ? _vm.model.marriage_status.rules
                            : null,"disabled":typeof _vm.model.marriage_status.disabled !==
                          'undefined'
                            ? _vm.model.marriage_status.disabled
                            : null},model:{value:(_vm.packageChosee),callback:function ($$v) {_vm.packageChosee=$$v},expression:"packageChosee"}}),_c('form-group-input-vue',{ref:"month_depature",attrs:{"placeholder":"","width-background":"","type":"date-mount","label":"Bulan Keberangkatan","need-label":true,"rules":"required"},model:{value:(_vm.month_depature),callback:function ($$v) {_vm.month_depature=$$v},expression:"month_depature"}})],1),(_vm.notEdit)?_c('div',{staticClass:"mt-5"},[_c('h5',[_vm._v("Unggah Data Jama'ah")]),_c('b-row',[_c('b-col',[_c('form-group-input-vue',{ref:"id_card",attrs:{"placeholder":" ","width-background":"","type":_vm.model.id_card.type,"label":'Foto KTP',"options":_vm.getOptions('id_card'),"need-label":true,"rules":typeof _vm.model.id_card.rules !== 'undefined'
                                ? _vm.model.id_card.rules
                                : null,"disabled":typeof _vm.model.id_card.disabled  !== 'undefined'
                                ? _vm.model.id_card.disabled
                                : null,"id":"id_card"},model:{value:(_vm.model.id_card.value),callback:function ($$v) {_vm.$set(_vm.model.id_card, "value", $$v)},expression:"model.id_card.value"}})],1)],1),_c('b-row',[_c('b-col',[_c('form-group-input-vue',{ref:"self_portrait",attrs:{"placeholder":" ","width-background":"","type":_vm.model.self_portrait.type,"label":'Pas Photo',"options":_vm.getOptions('self_portrait'),"need-label":true,"disabled":typeof _vm.model.self_portrait.disabled !==
                              'undefined'
                                ? _vm.model.self_portrait.disabled
                                : null,"id":"self_portrait"},model:{value:(_vm.model.self_portrait.value),callback:function ($$v) {_vm.$set(_vm.model.self_portrait, "value", $$v)},expression:"model.self_portrait.value"}})],1)],1),_c('b-row',[_c('b-col',[_c('form-group-input-vue',{ref:"chat_proof_scan_wa",attrs:{"placeholder":" ","width-background":"","type":_vm.model.self_portrait.type,"label":'SC WhatSapp konfirmasi',"need-label":true,"id":"chat_proof_scan_wa"},model:{value:(_vm.chat_proof_scan_wa),callback:function ($$v) {_vm.chat_proof_scan_wa=$$v},expression:"chat_proof_scan_wa"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('h5',[_vm._v("Alamat")]),_c('b-row',[_c('b-col',[_c('autocomplete-vue',{ref:"province",attrs:{"is-required":"","default-value":_vm.dataFieldEdit
                                ? _vm.dataFieldEdit.pilgrim.province.name
                                : '',"data-url":_vm.endpoint.provincy.url,"placeholder":"","label":"Provinsi"},on:{"getBackData":_vm.setAddress}})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('autocomplete-vue',{ref:"regency",attrs:{"is-required":"","default-value":_vm.dataFieldEdit
                                ? _vm.dataFieldEdit.pilgrim.regency.name
                                : '',"data-url":_vm.endpoint.regencies.url,"placeholder":"","label":"Kota"},on:{"getBackData":_vm.setAddress}})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('autocomplete-vue',{ref:"district",attrs:{"is-required":"","default-value":_vm.dataFieldEdit
                                ? _vm.dataFieldEdit.pilgrim.district.name
                                : '',"data-url":_vm.endpoint.districts.url,"placeholder":"","label":"Kecamatan"},on:{"getBackData":_vm.setAddress}})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('autocomplete-vue',{ref:"village",attrs:{"is-required":"","default-value":_vm.dataFieldEdit
                                ? _vm.dataFieldEdit.pilgrim.village.name
                                : '',"data-url":_vm.endpoint.villages.url,"placeholder":"","label":"Kelurahan"},on:{"getBackData":_vm.setAddress}})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('form-group-input-vue',{ref:"address",attrs:{"placeholder":" ","width-background":"","type":_vm.model.address.type,"label":typeof _vm.model.address.label === 'string'
                                ? _vm.model.address.label
                                : null,"options":_vm.getOptions('address'),"need-label":true,"rules":typeof _vm.model.address.rules !== 'undefined'
                                ? _vm.model.address.rules
                                : null,"disabled":typeof _vm.model.address.disabled !== 'undefined'
                                ? _vm.model.address.disabled
                                : null,"id":"id_card"},model:{value:(_vm.model.address.value),callback:function ($$v) {_vm.$set(_vm.model.address, "value", $$v)},expression:"model.address.value"}})],1)],1)],1),_c('div',{staticClass:"w-100 mb-5 d-flex justify-content-between"},[_c('b-button',{staticClass:"mt-3 float-right",staticStyle:{"color":"white"},attrs:{"variant":"warning"},on:{"click":function () { return _vm.$router.push('/order'); }}},[(_vm.isLoading)?_c('font-awesome-icon',{attrs:{"icon":"spinner","spin":""}}):_vm._e(),_vm._v(" Batal ")],1),_c('b-button',{staticClass:"mt-3 float-right",attrs:{"variant":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Kirim")])],1)],2)]}}])})],1)])]],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }