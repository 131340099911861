<template>
  <div>
    <Loading v-if="isLoading"></Loading>
    <b-row class="mb-4" v-else>
      <b-col cols="12">
        <b-card style="width: 60%; margin: 0 auto;" header-tag="header">
          <div id="form-check-out">
            <template>
              <div
                ref="modalJamaah"
                id="modal-data-jamaah"
                scrollable
                title="Isi Data Jama'ah"
                class="modal-custom"
              >
                <div class="p-3">
                  <ValidationObserver ref="form" v-slot="{ invalid }">
                    <form class="p-3">
                      <!-- <b-form-checkbox class="mb-3" :value="true" v-model="dataAkun" :unchecked-value="false">
                    Gunakan Data Akun Anda
                  </b-form-checkbox> -->
                      <h5>Data Diri</h5>
                      <template v-for="(m, index, idx) in model">
                        <b-row v-if="idx < 4">
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              :ref="index"
                              :type="m.type"
                              :label="
                                typeof m.label === 'string' ? m.label : null
                              "
                              :options="getOptions(index)"
                              :need-label="true"
                              class="input-gray"
                              v-model="m.value"
                              :rules="
                                typeof m.rules !== 'undefined' ? m.rules : null
                              "
                              :disabled="
                                typeof m.disabled !== 'undefined'
                                  ? m.disabled
                                  : null
                              "
                              :id="index"
                              :format="
                                typeof m.format != 'undefined' ? m.format : null
                              "
                            />
                          </b-col>
                        </b-row>
                      </template>
                      <b-row>
                        <b-col cols="6">
                          <form-group-input-vue
                            placeholder=" "
                            width-background
                            ref="dob"
                            :type="model.dob.type"
                            :label="
                              typeof model.dob.label === 'string'
                                ? model.dob.label
                                : null
                            "
                            :need-label="true"
                            v-model="model.dob.value"
                            :rules="
                              typeof model.dob.rules !== 'undefined'
                                ? model.dob.rules
                                : null
                            "
                            id="dob"
                          />
                        </b-col>
                        <b-col cols="6">
                          <form-group-input-vue
                            placeholder=" "
                            width-background
                            ref="job"
                            :type="model.job.type"
                            :label="
                              typeof model.job.label === 'string'
                                ? model.job.label
                                : null
                            "
                            :need-label="true"
                            v-model="model.job.value"
                            :rules="
                              typeof model.job.rules !== 'undefined'
                                ? model.job.rules
                                : null
                            "
                            id="job"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="6">
                          <form-group-input-vue
                            placeholder=" "
                            width-background
                            ref="gender"
                            :type="model.gender.type"
                            :label="
                              typeof model.gender.label === 'string'
                                ? model.gender.label
                                : null
                            "
                            :options="getOptions('gender')"
                            :need-label="true"
                            v-model="model.gender.value"
                            :rules="
                              typeof model.gender.rules !== 'undefined'
                                ? model.gender.rules
                                : null
                            "
                            :disabled="
                              typeof model.gender.disabled !== 'undefined'
                                ? model.gender.disabled
                                : null
                            "
                            id="gender"
                          />
                        </b-col>
                        <b-col cols="6">
                          <form-group-input-vue
                            placeholder=" "
                            width-background
                            ref="marriage_status"
                            :type="model.marriage_status.type"
                            :label="
                              typeof model.marriage_status.label === 'string'
                                ? model.marriage_status.label
                                : null
                            "
                            :options="getOptions('marriage_status')"
                            :need-label="true"
                            v-model="model.marriage_status.value"
                            :rules="
                              typeof model.marriage_status.rules !== 'undefined'
                                ? model.marriage_status.rules
                                : null
                            "
                            :disabled="
                              typeof model.marriage_status.disabled !==
                              'undefined'
                                ? model.marriage_status.disabled
                                : null
                            "
                            id="marriage_status"
                          />
                        </b-col>
                      </b-row>

                      <div class="mt-5">
                        <h5>Paket</h5>
                        <form-group-input-vue
                          ref="packageChosee"
                          placeholder=" "
                          width-background
                          type="enum"
                          label="Paket Pilihan"
                          :options="getOptions(packageList)"
                          :need-label="true"
                          v-model="packageChosee"
                          :rules="
                            typeof model.marriage_status.rules !== 'undefined'
                              ? model.marriage_status.rules
                              : null
                          "
                          :disabled="
                            typeof model.marriage_status.disabled !==
                            'undefined'
                              ? model.marriage_status.disabled
                              : null
                          "
                        />
                        <!-- <form-group-input-vue
                        placeholder=" "
                        width-background
                        type="enum"
                        label="Bandara Keberangkatan"
                        :options="getOptions(airlineDepatureList)"
                        :need-label="true"
                        v-model="airlineDepature"
                        rules="required"
                      />-->
                      <form-group-input-vue
                        ref="month_depature"
                        placeholder=""
                        width-background
                        type="date-mount"
                        label="Bulan Keberangkatan"
                        :need-label="true"
                        rules="required"
                        v-model="month_depature"
                        
                      /> 
                      </div>

                      <!-- <div v-if="notEdit" class="mt-5">
                        <h5>Pembayaran</h5>
                        <b-row>
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              type="number"
                              label="Nominal Pembayaran"
                              :need-label="true"
                              v-model="nominalPayment"
                              rules="required"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              type="enum"
                              label="Metode Pembayaran"
                              :options="{
                                'cash': 'Cash',
                                'transfer': 'Transfer'
                              }"
                              :need-label="true"
                              v-model="paymentMethod"
                              rules="required"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              :type="model.address.type"
                              :label="'Catatan Pembayaran'"
                              :need-label="true"
                              v-model="paymentNote"
                              :rules="'required'"
                              id="payment note"
                            />
                          </b-col>
                        </b-row>
                      </div> -->

                      <div v-if="notEdit" class="mt-5">
                        <h5>Unggah Data Jama'ah</h5>
                        <b-row>
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              ref="id_card"
                              :type="model.id_card.type"
                              :label="'Foto KTP'"
                              :options="getOptions('id_card')"
                              :need-label="true"
                              v-model="model.id_card.value"
                              :rules="
                                typeof model.id_card.rules !== 'undefined'
                                  ? model.id_card.rules
                                  : null
                              "
                              :disabled="
                                typeof model.id_card.disabled  !== 'undefined'
                                  ? model.id_card.disabled
                                  : null
                              "
                              id="id_card"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              ref="self_portrait"
                              :type="model.self_portrait.type"
                              :label="'Pas Photo'"
                              :options="getOptions('self_portrait')"
                              :need-label="true"
                              v-model="model.self_portrait.value"
                              :disabled="
                                typeof model.self_portrait.disabled !==
                                'undefined'
                                  ? model.self_portrait.disabled
                                  : null
                              "
                              id="self_portrait"
                            />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              ref="chat_proof_scan_wa"
                              :type="model.self_portrait.type"
                              :label="'SC WhatSapp konfirmasi'"
                              :need-label="true"
                              v-model="chat_proof_scan_wa"
                              id="chat_proof_scan_wa"
                            />
                          </b-col>
                        </b-row>
                      </div>

                      <div class="mt-5">
                        <h5>Alamat</h5>
                        <b-row>
                          <b-col>
                            <autocomplete-vue
                              ref="province"
                              is-required
                              :default-value="
                                dataFieldEdit
                                  ? dataFieldEdit.pilgrim.province.name
                                  : ''
                              "
                              :data-url="endpoint.provincy.url"
                              placeholder=""
                              @getBackData="setAddress"
                              label="Provinsi"
                            >
                            </autocomplete-vue>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col>
                            <autocomplete-vue
                              ref="regency"
                              is-required
                              :default-value="
                                dataFieldEdit
                                  ? dataFieldEdit.pilgrim.regency.name
                                  : ''
                              "
                              :data-url="endpoint.regencies.url"
                              placeholder=""
                              @getBackData="setAddress"
                              label="Kota"
                            />
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col>
                            <autocomplete-vue
                              ref="district"
                              is-required
                              :default-value="
                                dataFieldEdit
                                  ? dataFieldEdit.pilgrim.district.name
                                  : ''
                              "
                              :data-url="endpoint.districts.url"
                              placeholder=""
                              @getBackData="setAddress"
                              label="Kecamatan"
                            />
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col>
                            <autocomplete-vue
                              ref="village"
                              is-required
                              :default-value="
                                dataFieldEdit
                                  ? dataFieldEdit.pilgrim.village.name
                                  : ''
                              "
                              :data-url="endpoint.villages.url"
                              placeholder=""
                              @getBackData="setAddress"
                              label="Kelurahan"
                            />
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col>
                            <form-group-input-vue
                              placeholder=" "
                              width-background
                              ref="address"
                              :type="model.address.type"
                              :label="
                                typeof model.address.label === 'string'
                                  ? model.address.label
                                  : null
                              "
                              :options="getOptions('address')"
                              :need-label="true"
                              v-model="model.address.value"
                              :rules="
                                typeof model.address.rules !== 'undefined'
                                  ? model.address.rules
                                  : null
                              "
                              :disabled="
                                typeof model.address.disabled !== 'undefined'
                                  ? model.address.disabled
                                  : null
                              "
                              id="id_card"
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div class="w-100 mb-5 d-flex justify-content-between">
                        <b-button
                          variant="warning"
                          style="color: white"
                          class="mt-3 float-right"
                          @click="() => $router.push('/order')"
                        >
                          <font-awesome-icon
                            v-if="isLoading"
                            icon="spinner"
                            spin
                          ></font-awesome-icon>
                          Batal
                        </b-button>
                        <b-button
                          variant="primary"
                          @click="onSubmit"
                          class="mt-3 float-right"
                          >Kirim</b-button
                        >
                      </div>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </template>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { objectToOptions, scrollToError, serializeModel } from "@/_helpers";
import FormGroupInputVue from "@/components/FormGroupInput.vue";
import Checkout from "@/models/Checkout.js";
import Loading from "@/components/Loading.vue";
import Package from "@/models/Package";
import Addon from "@/models/Addon";
import Order from "@/models/Order";
import Bank from "@/models/Bank";
import AutocompleteVue from "@/components/Autocomplete.vue";

const checkoutModel = new Checkout();
const PackageModel = new Package();
const OrderModel = new Order();
const AddonModel = new Addon();
const BankModel = new Bank();

export default {
  components: {
    FormGroupInputVue,
    AutocompleteVue,
    Loading,
  },
  data: () => ({
    dataAkun: false,
    isLoading: false,
    url: process.env.VUE_APP_API_URL,
    cacheForm: "AutocompleteVue",
    endpoint: {
      provincy: {
        url: process.env.VUE_APP_API_URL + "provinces?name=",
        search: "",
      },
      regencies: {
        url: "",
        search: "",
      },
      districts: {
        url: "",
        search: "",
      },
      villages: {
        url: "",
        search: "",
      },
    },
    model: checkoutModel.init(checkoutModel.secondFields),
    validateAdress: false,
    isLoading: false,

    // List Form
    documentType: {},
    packageList: [],
    airlineDepatureList: [],
    bankList: [],

    // data post
    month_depature: null,
    paymentMethod: null,
    airlineDepature: null,
    packageChosee: null,
    chat_proof_scan_wa: null,

    // IF Edit mode
    dataFieldEdit: null,

    // Payment
    nominalPayment: null,
    paymentNote: null,
    paymentAttacment: null,
  }),
  watch: {
    dataAkun: function(value) {
      if (value == false) {
        this.model.name.value = null;
        this.model.phone_number.value = null;
        this.model.gender.value = null;
        return;
      }
      let currentAccount = this.$store.getters["account/accountData"];
      this.model.name.value = currentAccount.name;
      this.model.phone_number.value = currentAccount.phone_number;
      this.model.gender.value = currentAccount.gender;
    },
  },
  computed: {
    notEdit: function() {
      return this.$route.params.id ? false : true;
    },
    bankListOption: function() {
      const bankOption = this.bankList.reduce(
        (previousValue, currentValue, index) => {
          if (index == 0) return { [currentValue.id]: currentValue.name };
          else
            return { ...previousValue, [currentValue.id]: currentValue.name };
        },
        0
      );

      return bankOption;
    },
  },
  methods: {
    getAddon: async function() {
      const addon = await AddonModel.list();
      this.airlineDepatureList = addon.data
        .filter((data) => data.type == "depature")
        .reduce((p, c, i) => {
          if (i == 0) return { [c.id]: c.name };
          else return { ...p, [c.id]: c.name };
        }, 0);
    },
    setFields: function() {
      const url = process.env.VUE_APP_API_URL;
      this.endpoint.regencies =
        url +
        "regencies?province_id=" +
        this.model.province_id.value +
        "&name=";
    },
    setAddress: function(result, setDefault = null) {
      try {
        if (result.hasOwnProperty("province_id") || setDefault != null) {
          this.model.regency_id.value = result.id;
          this.endpoint.districts.url =
            this.url + "districts?regency_id=" + result.id + "&name=";
          this.endpoint.regencies.search = result.name;
        } else if (result.hasOwnProperty("regency_id") || setDefault != null) {
          this.model.district_id.value = result.id;
          this.endpoint.villages.url =
            this.url + "villages?district_id=" + result.id + "&name=";
          this.endpoint.districts.search = result.name;
        } else if (result.hasOwnProperty("district_id") || setDefault != null) {
          this.model.village_id.value = result.id;
          this.endpoint.villages.search = result.name;
          this.validateAdress = true;
        } else {
          this.model.province_id.value = result.id;
          this.endpoint.regencies.url =
            this.url + "regencies?province_id=" + result.id + "&name=";
          this.endpoint.provincy.search = result.name;
        }
      } catch (e) { 
        this.validateAdress = false;
      }
    },

    getOptions: function(key) {
      if (typeof key == "object") return objectToOptions(key);
      else
        return typeof this.model[key].options !== "undefined"
          ? objectToOptions(this.model[key].options)
          : null;
    },
    onSubmit: function() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          scrollToError(this.$refs.form);
          return false;
        }

        let checkoutData = new FormData();
        let formData;

        formData = this.notEdit
          ? {
              self_data: this.dataAkun ? 1 : 0,
              bank_id: this.bankList[0].id,
              package_id: this.packageChosee,
              month_depature: this.month_depature,
              chat_proof_scan_wa: this.chat_proof_scan_wa,
              ...serializeModel(this.model),
            }
          : {
              month_depature: this.month_depature,
              bank_id: this.paymentMethod,
              package_id: this.packageChosee,
              ...serializeModel(this.model),
            };
        
        for (const key in formData) {
          if (["self_data", "package_id", "bank_id"].includes(key))
            checkoutData.append(key, formData[key]);
          else checkoutData.append(`pilgrim[${key}]`, formData[key]);
        }

        // checkoutData.append(`addons[0]`, this.airlineDepature);
        // checkoutData.append(`addons[0]`, 1);

        if (this.notEdit)
          OrderModel.checkoutPost(checkoutData)
            .then((resp) => {
              // let postPaymentHistory = new FormData();

              // postPaymentHistory.append("order_id", resp.data);
              // postPaymentHistory.append("package_id", this.packageChosee);
              // postPaymentHistory.append("amount", this.nominalPayment);
              // postPaymentHistory.append("payment_method", "cash");
              // postPaymentHistory.append("type", "debit");
              // postPaymentHistory.append("note", this.paymentNote);

              // OrderModel.addPaymentHistory(postPaymentHistory).then((res) => {
              //   this.$store.dispatch('notification/success', 'Pendaftaran Berhasil.')
              //   this.$router.push('/order')
              // });
              console.log(resp)
              this.$store.dispatch('notification/success', 'Pendaftaran Berhasil')
              this.$router.push('/order/' + resp.data)
            })
            .catch((err) => {
              this.$store.dispatch("notification/error", err);
            });
        else 
          OrderModel.orderUpdate(checkoutData, this.$route.params.id)
            .then(resp => {
              this.$store.dispatch('notification/success', 'Edit Pendaftaran Berhasil.')
              this.$router.push('/order/' + resp.data)
            }).catch(err => {
              console.log(err)
            })
      });
    },
    getIdUploadData: function() {
      OrderModel.getUploadDokumen().then((resp) => {
        this.documentType = resp.reduce(
          (previousValue, currentValue, index) => {
            if (index == 0) return { [currentValue.name]: currentValue.id };
            else
              return { ...previousValue, [currentValue.name]: currentValue.id };
          },
          0
        );
      });
    },
    getBankPayment: async function() {
      const bank = await BankModel.list();
      this.bankList = bank.data;
    },
    getAddress: function() {
      this.$http.get(process.env.VUE_APP_API_URL + "provinces").then((resp) => {
        let provincy = {};
        resp.data.data.forEach((dt) => {
          provincy[dt.id] = dt.name;
        });
        this.model.province_id.options = provincy;
      });
    },
    getPackage: async function() {
      const getPackage = await PackageModel.list();

      this.packageList = getPackage.data.reduce(
        (previousValue, currentValue, index) => {
          if (index == 0) return { [currentValue.id]: currentValue.name };
          else
            return { ...previousValue, [currentValue.id]: currentValue.name };
        },
        0
      );

      this.notEdit && this.$refs.packageChosee.setValue(this.packageChosee);
    },
    setInputValue: function() {
      Object.keys(this.dataFieldEdit.pilgrim).map((key) => {
        if (this.model[key])
          this.model[key].value = this.dataFieldEdit.pilgrim[key];
      });
      this.paymentMethod = this.dataFieldEdit.bank_id;
      this.model.gender.value = this.dataFieldEdit.user.gender;
      this.packageChosee = this.dataFieldEdit.details[0].orderdetailable_id;
      this.month_depature = this.dataFieldEdit.month_depature;
      this.$nextTick(() => {
        this.$refs.dob.setValue(this.dataFieldEdit.pilgrim.dob);
      });
      setTimeout(() => {
        this.endpoint.regencies.url =
          this.url +
          "regencies?province_id=" +
          this.dataFieldEdit.pilgrim.province.id +
          "&name=";
        this.endpoint.regencies.search = this.dataFieldEdit.pilgrim.regency.name;

        this.endpoint.districts.url =
          this.url +
          "districts?regency_id=" +
          this.dataFieldEdit.pilgrim.regency.id +
          "&name=";
        this.endpoint.districts.search = this.dataFieldEdit.pilgrim.district.name;

        this.endpoint.villages.url =
          this.url +
          "villages?district_id=" +
          this.dataFieldEdit.pilgrim.district.id +
          "&name=";
        this.endpoint.villages.search = this.dataFieldEdit.pilgrim.village.name;
        this.$forceUpdate();
      }, 500);
    },
    getDetailOrder: function() {
      this.isLoading = true;
      OrderModel.find(this.$route.params.id)
        .then((resp) => {
          this.dataFieldEdit = resp;
          this.isLoading = false;
          this.setInputValue();
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
  },
  created: function() {
    if (this.$route.params.id) {
      this.getDetailOrder();
    }
    this.getBankPayment();
    this.getAddon();
    this.getAddress();
    this.getIdUploadData();
    this.getPackage();
  },
};
</script>
